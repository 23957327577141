import React from "react";

type RegistrationRightProps = {
  title: string;
  subtitle1: string;
  body1: string;
  subtitle2: string;
  body2: string;
  subtitle3: string;
  body3: string;
  subtitle4?: string;
};

const ProgressBar: React.FC<RegistrationRightProps> = ({
  title,
  subtitle1,
  body1,
  subtitle2,
  body2,
  subtitle3,
  body3,
  subtitle4 = "",
}) => {
  return (
    <div className="block mt-12">
      <div className="p-16 h-1/2 flex flex-col justify-evenly items-start">
        <h3 className="font-semibold text-3xl mb-8">{title}</h3>
        <div className="mb-8">
          <h5 className="font-semibold text-xl">{subtitle1}</h5>
          <div className="text-base">{body1}</div>
        </div>
        <div className="mb-8">
          <h5 className="font-semibold text-xl">{subtitle2}</h5>
          <div className="text-base">{body2}</div>
        </div>
        <div className="mb-8">
          <h5 className="font-semibold text-xl">{subtitle3}</h5>
          <div className="text-base">{body3}</div>
        </div>
        <div className="mb-8">
          <h5 className="font-semibold text-xl">{subtitle4}</h5>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
